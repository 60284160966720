/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Rob Romijnders",
  title: "",
  subTitle:
    "PhD in Machine Learning. Interested in representation learning, decentralized machine learning, and differential privacy.",
  resumeLink:
    "https://github.com/RobRomijnders/random/blob/master/cv_rob_romijnders.pdf",
  googleScholarLink: "https://scholar.google.com/citations?user=P1kN6KEAAAAJ",
};

// Your Social Media Link

const socialMediaLinks = {
  github: "https://github.com/RobRomijnders",
  linkedin: "https://www.linkedin.com/in/robromijnders/",
  gmail: "romijndersrob@gmail.com",
  stackoverflow: "https://stackoverflow.com/users/6104317/rob-romijnders",
  // Instagram and Twitter are also supported in the links!
};

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "PhD Student",
      company: "University of Amsterdam",
      companylogo: require("./assets/images/uva.png"),
      date: "September 2021 – present",
      desc: "Decentralized machine learning",
      descBullets: [
        "Currently working on differential privacy",
        "Collaboration with Qualcomm Research",
        "Supervised by Max Welling, Christos Louizos and Yuki Asano",
      ],
    },
    {
      role: "AI resident",
      company: "Google",
      companylogo: require("./assets/images/google_logo.png"),
      date: "October 2019 – July 2021",
      desc: "Research focusing on representation learning in image and video",
      descBullets: [
        "Mentored by Mario Lucic and Neil Houlsby.",
        "Resulted in five publications.",
      ],
    },
    {
      role: "Machine learning engineer",
      company: "Frosha.io",
      companylogo: require("./assets/images/frosha_logo.png"),
      date: "October 2016 - July 2019",
      desc: "Entity resolution in large CRM systems",
      descBullets: [
        "Startup, employee #1",
        "Worked on Text parsing using Maximum likelihood in RNN models",
      ],
    },
    {
      role: "MSc Electrical Engineering",
      company: "Eindhoven University",
      companylogo: require("./assets/images/tue_whiten.png"),
      date: "2015 - 2018",
      desc: "Graduated with honors, top 10% of class",
      descBullets: [
        "Thesis published IEEE WACV",
        "Courses in Signal processing, optimization, machine learning",
        "Exchange semester with National University of Singapore",
      ],
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "False", // Set true or false to show Contact profile using Github, defaults to false
};

// Your Achievement Section Include Your Certification Talks and More

const publicationsSection = {
  title: "Academic publications ",
  subtitle: "Academic publications I have co-authored",

  publicationCards: [
    {
      title:
        "DNA: Differentially private Neural Augmentation for contact tracing",
      subtitle:
        "Rob Romijnders, Christos Louizos, Yuki M. Asano, Max Welling",

      urlPaper: "https://arxiv.org/abs/2404.13381",
      image: require("./assets/images/dna.jpg"),
      footerLink: [
        {
          name: "Private ML workshop (spotlight talk)",
          url: "https://arxiv.org/abs/2404.13381",
        },
        {
          name: "ICLR workshops 2024",
          url: "https://pml-workshop.github.io/iclr24/",
        },
        {
          name: "Github",
          url: "https://github.com/RobRomijnders/dna",
        },
        {
          name: "slides",
          url: "https://github.com/RobRomijnders/random/blob/master/dna_slides.pdf",
        },
        {
          name: "poster",
          url: "https://github.com/RobRomijnders/random/blob/master/dna_poster.pdf",
        }
      ],
    },
    {
      title:
        "Protect Your Score: Contact Tracing With Differential Privacy Guarantees",
      subtitle:
        "Rob Romijnders, Christos Louizos, Yuki M. Asano, Max Welling",

      urlPaper: "https://arxiv.org/abs/2312.11581",
      image: require("./assets/images/dpfn.jpg"),
      footerLink: [
        {
          name: "AAAI 2024",
          url: "https://arxiv.org/abs/2312.11581",
        },
        {
          name: "Github",
          url: "https://github.com/RobRomijnders/dpfn_aaai",
        }
        ,
        {
          name: "Github C++",
          url: "https://github.com/RobRomijnders/dpfn_cpp",
        },
        {
          name: "Poster",
          url: "https://github.com/RobRomijnders/random/blob/master/aaai_poster.pdf",
        },
        {
          name: "Slides",
          url: "https://github.com/RobRomijnders/random/blob/master/aaai_slides.pdf",
        }
      ],
    },
    {
      title:
        "No Time to Waste: practical statistical contact tracing with few low-bit messages",
      subtitle:
        "Rob Romijnders, Yuki M. Asano, Christos Louizos, Max Welling",

      urlPaper: "https://proceedings.mlr.press/v206/romijnders23a.html",
      image: require("./assets/images/nttw.jpg"),
      footerLink: [
        {
          name: "AISTATS 2023",
          url: "https://proceedings.mlr.press/v206/romijnders23a.html",
        },
        {
          name: "Open source code",
          url: "https://github.com/QUVA-Lab/nttw",
        }
      ],
    },
    {
      title:
        "The Effect of Covariate Shift and Network Training on Out-of-Distribution Detection",
      subtitle:
        "Simon Mariani, Sander R. Klomp, Rob Romijnders, Peter H.N. de With",

      urlPaper: "https://research.tue.nl/en/publications/the-effect-of-covariate-shift-and-network-training-on-out-of-dist",
      image: require("./assets/images/cov_shift.png"),
      footerLink: [
        {
          name: "VISGRAPP 2023",
          url: "https://research.tue.nl/en/publications/the-effect-of-covariate-shift-and-network-training-on-out-of-dist",
        },
        {
          name: "Open source code",
          url: "https://github.com/SimonMariani/OOD-detection",
        }
      ],
    },
    {
      title:
        "Beyond transfer learning: Co-finetuning for action localisation",
      subtitle:
        "Anurag Arnab, Xuehan Xiong, Alexey Gritsenko, Rob Romijnders, Josip Djolonga, Mostafa Dehghani, Chen Sun, Mario Lučić, Cordelia Schmid",

      urlPaper: "https://arxiv.org/abs/2207.03807",
      image: require("./assets/images/beyond_transfer.jpg"),
      footerLink: [
        {
          name: "arxiv 2022",
          url: "https://arxiv.org/abs/2207.03807",
        },
      ],
    },
    {
      title:
        "Impact of Aliasing on Generalization in Deep Convolutional Networks",
      subtitle:
        "Cristina Vasconcelos, Hugo Larochelle, Vincent Dumoulin, Rob Romijnders, Nicolas Le Roux, Ross Goroshin",

      urlPaper: "https://arxiv.org/abs/2108.03489",
      image: require("./assets/images/alias.png"),
      footerLink: [
        {
          name: "ICCV 2021",
          url: "https://arxiv.org/abs/2108.03489",
        },
      ],
    },
    {
      title:
        "Revisiting the Calibration of Modern Neural Networks",
      subtitle:
        "Matthias Minderer, Josip Djolonga, Rob Romijnders, Frances Hubis, Xiaohua Zhai, Neil Houlsby, Dustin Tran, Mario Lucic",

      urlPaper: "https://arxiv.org/abs/2106.07998",
      image: require("./assets/images/calibration.png"),
      footerLink: [
        {
          name: "NeurIPS 2021",
          url: "https://arxiv.org/abs/2106.07998",
        },
      ],
    },
    {
      title:
        "On Robustness and Transferability of Convolutional Neural Networks",
      subtitle:
        "Josip Djolonga, Jessica Yung, Michael Tschannen, Rob Romijnders, Lucas Beyer, Alexander Kolesnikov, Joan Puigcerver, Matthias Minderer, Alexander D'Amour, Dan Moldovan, Sylvan Gelly, Neil Houlsby, Xiaohua Zhai, Mario Lucic",

      urlPaper: "https://arxiv.org/abs/2007.08558",
      image: require("./assets/images/robust.png"),
      footerLink: [
        { name: "CVPR 2021", url: "https://arxiv.org/abs/2007.08558" },
        {
          name: "Open source code",
          url: "https://github.com/google-research/robustness_metrics",
        }
      ],
    },
    {
      title:
        "SI-SCORE: An image dataset for fine-grained analysis of robustness to object location, rotation and size.",
      subtitle:
        "Jessica Yung, Rob Romijnders, Alexander Kolesnikov, Lucas Beyer, Josip Djolonga, Neil Houlsby, Sylvain Gelly, Mario Lucic, Xiaohua Zhai",

      urlPaper: "https://arxiv.org/abs/2104.04191",
      image: require("./assets/images/siscore.png"),
      footerLink: [
        {
          name: "ICLR 2021 workshops",
          url: "https://arxiv.org/abs/2104.04191",
        },
        {
          name: "Open source data",
          url: "https://www.tensorflow.org/datasets/catalog/siscore",
        }
      ],
    },
    {
      title:
        "Representation learning from videos in-the-wild: An object-centric approach",
      subtitle:
        "Rob Romijnders, Aravindh Mahendran, Michael Tschannen, Josip Djolonga, Marvin Ritter, Neil Houlsby, Mario Lucic",
      urlPaper: "https://arxiv.org/abs/2010.02808",
      image: require("./assets/images/vivi_objects.png"),
      footerLink: [
        {
          name: "WACV 2021",
          url: "https://arxiv.org/abs/2010.02808",
        },
      ],
    },
    {
      title:
        "Data Selection for training Semantic Segmentation CNNs with cross-dataset weak supervision",
      subtitle: "Panagiotis Meletis, Rob Romijnders, Gijs Dubbelman",
      urlPaper: "https://arxiv.org/abs/1907.07023",
      image: require("./assets/images/itsc.png"),
      footerLink: [
        { name: "IEEE ITSC `19", url: "https://arxiv.org/abs/1907.07023" },
      ],
    },
    {
      title:
        "Applying deep bidirectional LSTM and mixture density network for basketball trajectory prediction",
      subtitle:
        "Yu Zhao, Rennong Yang, Guillaume Chevalier, Rajiv C Shah, Rob Romijnders",
      urlPaper: "https://arxiv.org/abs/1708.05824",
      image: require("./assets/images/rnn_bb_2.png"),
      footerLink: [
        {
          name: "Optik `19",
          url: "https://www.sciencedirect.com/science/article/abs/pii/S0030402617316662",
        },
        {
          name: "Journal article",
          url: "https://www.sciencedirect.com/science/article/abs/pii/S0030402617316662",
        },
      ],
    },
    {
      title:
        "A domain agnostic normalization layer for unsupervised adversarial domain adaptation",
      subtitle: "Rob Romijnders, Panagiotis Meletis, Gijs Dubbelman",
      urlPaper: "https://arxiv.org/abs/1809.05298",
      image: require("./assets/images/uada.png"),
      footerLink: [
        // { name: "IEEE WACV `18", url: "https://arxiv.org/abs/1809.05298" },
        {
          name: "WACV `18",
          url: "https://www.computer.org/csdl/proceedings-article/wacv/2019/197500b866/18j8EDR8lTG",
        },
        {
          name: "Open source code",
          url: "https://github.com/RobRomijnders/dan",
        }
      ],
    },
    {
      title: "Applying deep learning to basketball trajectories",
      subtitle: "Rajiv Shah, Rob Romijnders",
      urlPaper: "https://arxiv.org/abs/1608.03793",
      image: require("./assets/images/rnn_bb.png"),
      footerLink: [
        {
          name: "KDD workshops `16",
          url: "https://www.researchgate.net/publication/306187086_Applying_Deep_Learning_to_Basketball_Trajectories",
        },
      ],
    },

  ],
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "Public & recorded talks",

  talks: [
    {
      title: "Bayesian Deep learning with 10% of the weights",
      subtitle: "PyData 2018",
      slides_url:
        "https://github.com/RobRomijnders/weight_uncertainty/blob/master/docs/presentation/versions/final_pydata18_bayes_nn_rob_romijnders_1.pdf",
      event_url: "https://www.youtube.com/watch?v=Z7VN7oRA6TY",
    },
    {
      title: "Using deep learning in natural language processing",
      subtitle: "PyData 2017",
      slides_url:
        "https://github.com/RobRomijnders/weight_uncertainty/blob/master/docs/presentation/versions/final_pydata18_bayes_nn_rob_romijnders_1.pdf",
      event_url: "https://www.youtube.com/watch?v=HVdPWoZ_swY&t=840s",
    },
  ],
};

const contactInfo = {
  title: "Contact details:",
  subtitle: "Get in touch via email.",
  // number: "+31 6 546 44 219",
  email_address: "romijndersrob@gmail.com",
};

export {
  greeting,
  socialMediaLinks,
  publicationsSection,
  workExperiences,
  openSource,
  talkSection,
  contactInfo,
};
//export { greeting, socialMediaLinks, skillsSection, techStack, workExperiences, openSource, bigProjects, achievementSection, blogSection, talkSection, podcastSection, contactInfo , twitterDetails};
