import React, { useState, useEffect, lazy, Suspense } from "react";

import Contact from "../contact/Contact";
import Loading from "../loading/Loading";

const renderLoader = () => <Loading />;

export default function Profile() {
  const [prof, setrepo] = useState([]);

  return <Contact />;
}
